


.navbar-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5vh;
  max-width: 100vw;
  min-width: 100vw;
  background-color: #312C63;
  position: fixed;
  z-index: 2;
  border-bottom: .2vmin dashed #07F49E;
  padding-left: 2vw;
  padding-right: 2vw;
}

.fin-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  color: #07F49E;
  width: 20vw;
  height: 5vh;
  margin: 0;
}

.fin-logo > p { 
  font-family: 'Rubik Iso', cursive;
  font-size: 3vmin;
}

.nav-linkz {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5vw;
  color: #07F49E;
  min-width: 50vw;
  height: 5vh;
  margin: 0;
}

.nav-linkz > button {
  height: 3.3vh;
  border: .1vmin solid #07F49E;
  color: #07F49E;
  background: none;
  border-radius: .5vmin;
  min-width: 10vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2vmin;
  font-weight: bold;
  padding: .5vmin;
}

.nav-linkz > button:hover {
  cursor:pointer;
}

.CV {
  color: #07F49E;
  text-decoration: underline;
  font-weight: bold;
}

@media (max-width: 480px) {
  .navbar-top {
    height: 9vmin;
  }
  .nav-linkz > button {
    font-size: 3vmin;
    padding-left: 2vmin;
    padding-right: 2vmin;
  }
  .fin-logo {
    width: 40%;
  }
  .fin-logo > p {
    font-size: 5vmin;
  }
}

.active {
  background-color: #07F49E;
  color: #312C63;
}
