

.projects-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  max-height: 90vh;
  padding: 5vmin;
}

.projects {
  font-size: 6vmin;
  color: #D8E9E9;
  font-weight: bold;
  margin-bottom: 2vmin;
}

ul {
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vmin;
}

.p2, .p4 {
  align-self: flex-end;
}

.project-li {
  list-style: none;
  width: 70%;
  height: 18vh;
  display: grid;
  grid-template-columns: 4fr 5fr;
  grid-template-rows: 1fr 3fr 1.4fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border: .25vmin dashed #07f49db9;
}
  .img-section { grid-area: 1 / 1 / 4 / 2; }
  .project-links { grid-area: 1 / 2 / 2 / 3; }
  .project-desc { grid-area: 2 / 2 / 3 / 3; }
  .built-with { grid-area: 3 / 2 / 4 / 3; }

.img-section {
  height: 100%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}



.img {
  height: auto;
  max-width: 60%;
  max-height: 17vh;
}

.project-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  padding-right: 2.5vmin;
}

.project-links > a {
  margin: 0;
}

.readme {
  color:#D8E9E9;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.5vmin;
}

.project-a > i {
  margin: 0;
  font-size: 1.2vmin;
  margin-top: .3vmin ;
}

.project-a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-self: flex-start;
  margin: 0;
  gap: .5vmin;
  align-items: center;
  align-self: center;
  text-align: left;
  width: 30%;  
  color:#D8E9E9;
}
.project-title, .project-desc {
  margin: 0;
  text-align: left;
}


.project-title {
  color: #D8E9E9;
  font-weight: bold;
}

.project-a:hover {
  color: #07F49E;
  .project-title {
    color: #07F49E;
  }
}

.readme:hover {
  color: #07F49E;
}

.project-desc {
  color: #d8e9e983;
  font-size: 1.7vmin;
  word-wrap: break-word;
  padding-right: 1vmin;
}



.built-with {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  color: #07F49E;
}

.built-with-li {
  background-color: #ffffff00;
  border: .1vmin solid #07F49E;
  font-size: 1.3vmin;
  padding: .5vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .5vmin;
}

@media (max-width: 480px) {
  
  .projects-section {
    align-items: center;
    width: 90vw;
    margin-top: -13vmin;
  }
  ul {
    align-items: center;
  }
  .p2, .p4 {
    align-self: center;
  }
  .projects {
    width: 90vw;
    text-align: left;
    margin-top: -6vmin;
  }
  .project-li {
    width: 90vw;
    min-height: 18.5vh;
    max-width: 90vmin;
  }
  .project-a > i {
    font-size: 3vmin;
  }
  .readme {
    font-size: 3vmin;
  }
  .project-desc {
    font-size: 2.9vmin;
    padding-right: 2vmin;
  }
  .built-with-li {
    font-size: 2.3vmin;
    font-weight: bold;
  }
  .img {
    height: auto;
    max-width: 90%;
  }
}