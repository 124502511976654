::-webkit-scrollbar {
  width: 1vw;
}

::-webkit-scrollbar-thumb {
  background-color: #07f49d85;
  border-radius: 2vmin;
  
}

::-webkit-scrollbar-track {
  background-color: #312C63;
  margin-top: 5.5vh;
}

html {
  width: 100%;
  margin: 0 auto;
  overflow-y: hidden;
}

* {
  margin: 0 auto;
  font-family: 'Inter', sans-serif;
}

body {
  background-color: #312C63;
}

.scroll-align {
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding: 0px;
  height: 100vh;
}
@media (max-width: 480px) {
  .scroll-align {
    scroll-snap-type: block mandatory;
  }
}

#welcome,
#content,
#skills,
#experience {
  scroll-snap-align: start;
}


.main-bg {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #312C63;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}







