.welcome-section {
  width: 80vw;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: .6vmin;
}

.hi, .name, .title, .statement {
  text-align: left;
  width: 100%;
}

.hi {
  color: #07F49E;
}

.name {
  color: #D8E9E9;
  font-size: 6vmin;
  font-weight: bold;
  text-shadow:0px 0px 0 rgb(194,211,211),1px 1px 0 rgb(171,188,188),
  2px 2px 0 rgb(149,166,166),
  3px 3px 0 rgb(127,144,144),
  4px 4px 3px rgba(0,0,0,0),
  4px 4px 1px rgba(0,0,0,0.5),
  0px 0px 3px rgba(0,0,0,.2);
}

.title {
  color: #d8e9e983;
  font-size: 6vmin;
}

.statement {
  color: #bfccccad;
  width: 100%;
  font-size: 2vmin;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: .5vw;
  width: 100%;
  margin-top: 2vmin;
}

.socials > a {
  margin: 0;
}

.ghub-link {
  margin: 0;
}

.gbutton, .inbutton, .emailbutton {
  background-color: #ffffff00;
  color: #07F49E;
  border: .1vmin solid #07F49E;
}

.gbutton:hover, .inbutton:hover, .emailbutton:hover {
  background-color: #07F49E;
  color:#312C63;
  border: none;
  box-shadow: 0px 0px 5px 1px #07F49E;
}

.linkedin {
  font-size: 7.1vmin;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#07F49E;
}

.linkedin > i {
  margin-bottom: -0.61vmin;
}

@media (max-width: 480px) {
  .name {
    text-shadow: none;
  }
  .welcome-section {
    height: 80vh;
    width: 90vw;
    gap: 1vmin;
    margin-top: -19vmin;
  }
  .statement {
    margin-bottom: 5vmin;
    font-size: 3.3vmin;
  }
  .socials {
    margin-bottom: .5vmin;
    gap: 1.8vmin;
  }
}