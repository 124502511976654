.skills-section {
  width: 80vw;
  height: 80vh;
  margin-top: 5vh;
}

#skills {
  display: flex;
  justify-content: space-between;
}
.skills-title {
  text-align: left;
  font-size: 6vmin;
  color: #D8E9E9;
  font-weight: bold;
  margin-bottom: 6vmin;
}

.icons {
  width: 100%;
  height: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 3.5vmin;
}

.icons > i {
  color: #07f49dae;
  font-size: 15vmin;
  display: flex;
  flex-direction: column;
  gap: 2vmin;
  align-items: center;
  max-height: 1.5em;
}

.icon-text {
  font-size: 2vmin;
  text-align: center;
  color: #07f49db4;
}

.skills-footer {
  width: 100vw;
  margin: 0;
  height: 9vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-end;
  color:#07f49d;
}

.skills-footer > h1 {
  font-weight: bold;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3vmin;
  margin: 0;
  width: 100%;
  border-top: .3vmin dashed #07f49d;
  height: 90%;
}

.contact-info > i, .contact-info > h3 {
  margin: 0;
}

@media (max-width: 480px) {
  .skills-section {
    height: 90vh;
  }
  .skills-title {
    margin-top: 2vmin;
  }
  .icons {
    margin-top: -3vmin;
  }
}