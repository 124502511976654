.experience-section {
  height: 80vh;
  width: 80vw;
}

.experience-title {
  color: #D8E9E9;
  font-size: 6vmin;
  font-weight: bold;
  width: 80vw;
  text-align: left;
}

.experience-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.experience-li {
  border: .3vmin dashed #07F49E;
  width: 49%;
  height: 35vh;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr) 5fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  color: #07F49E;
  }
  
  .company-name { grid-area: 1 / 1 / 2 / 2; }
  .date { grid-area: 1 / 2 / 2 / 3; }
  .position { grid-area: 2 / 1 / 3 / 3; }
  .description { grid-area: 3 / 1 / 4 / 3; }

.company-name, .position, .description {
  display: flex;
  justify-self: flex-start;
  align-items: center;
  padding-left: 1vmin;
  font-size: 2vmin;
}

.company-name, .date {
  width: 100%;
}

.date {
  display: flex;
  justify-content: flex-end;
  padding-right: 1vmin;
  align-items: center;
  border-left: .3vmin dashed #07F49E;
  font-size: 2vmin;
}

.position {
  border-top: .3vmin dashed #07F49E;
  border-bottom: .3vmin dashed #07F49E;
  width: 100%;
  color: #07F49E;
}

.description {
  align-items: flex-start;
  color: #D8E9E9;
  font-size: calc(10px + 0.35vw);
  word-wrap: break-word;
  padding: 1vmin 1.3vmin 1vmin 1.3vmin;
}

.description > li {
  text-align: left;
}


@media (max-width: 480px) {
  .experience-section {
    width: 90vw;
    height: 90vh;
  }
  .experience-list {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 90vw;
  }
  .experience-li {
    width: 100%;
    max-height: 25vh;
  }
  .company-name, .date, .description, .position {
    padding: 1vmin;
  }
  .company-name, .date, .position {
    font-size: 3.2vmin;
  }
  .description {
    font-size: 2.6vmin;
    padding: 1.5vmin;

  }
}